import LayoutPrivate from '@/layouts/private/layout-private.vue';
import oBlock from '@/objects/o-block/o-block.vue';

export default {
  name: 'PageNotFound',
  components: {
    'layout-private': LayoutPrivate,
    'o-block': oBlock
  }
};
